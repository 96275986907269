jQuery(document).ready(function ($) {
  $('#mobile-nav').removeClass('nav-active')
  $('.pp a').removeClass('.sf-with-ul')

  // Back to top button
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.back-to-top').fadeIn('slow');
    } else {
      $('.back-to-top').fadeOut('slow');
    }
  });
  $('.back-to-top').click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 1500, 'easeInOutExpo');
    return false;
  });

  // Initiate the wowjs animation library
  new WOW().init();

  // Initiate superfish on nav menu
  $('.nav-menu').superfish({
    animation: {
      opacity: 'show'
    },
    speed: 400
  });

  // Mobile Navigation
  // $(document).on('click','.navbar-toggler',function(){
  //   $('body').toggleClass('mobile-nav-active');
  //   $('.navbar-menu').toggleClass('mobile-nav');
  // });

  if ($('.navbar-menu').length) {
    var $mobile_nav = $('.navbar-menu').clone().prop({
      id: 'mobile-nav'
    });
    $mobile_nav.find('> ul').attr({
      'class': '',
      'id': ''
    });
    $('body').append($mobile_nav);
    $('body').prepend('<button type="button" id="mobile-nav-toggle"><i class="fa fa-bars"></i></button>');
    $('body').append('<div id="mobile-body-overly"></div>');
    $('#mobile-nav').find('.menu-has-children').prepend('<i class="fa fa-chevron-down"></i>');

    $(document).on('click', '.menu-has-children i', function (e) {
      $(this).next().toggleClass('menu-item-active');
      $(this).nextAll('ul').eq(0).slideToggle();
      $(this).toggleClass("fa-chevron-up fa-chevron-down");
    });

    $(document).on('click', '#mobile-nav-toggle', function (e) {
      $('body').toggleClass('mobile-nav-active');
      $('#mobile-nav').toggleClass('nav-active');
      $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
      $('#mobile-body-overly').toggle();
    });

    $(document).on('click', function (e) {
      var container = $("#mobile-nav, #mobile-nav-toggle, #loginWithOtp .close");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        if ($('body').hasClass('mobile-nav-active')) {
          $('body').removeClass('mobile-nav-active');
          $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
          $('#mobile-body-overly').fadeOut();
        }
      }
    });
  } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
    $("#mobile-nav, #mobile-nav-toggle").hide();
  }

  // Smooth scroll for the menu and links with .scrollto classes

  $('.nav-menu a, #mobile-nav a, .scrollto').on('click', function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      if (target.length) {
        var top_space = 0;

        if ($('#header').length) {
          top_space = $('#header').outerHeight();

          if (!$('#header').hasClass('header-fixed')) {
            top_space = top_space - 20;
          }
        }

        $('html, body').animate({
          scrollTop: target.offset().top - top_space
        }, 1500, 'easeInOutExpo');

        if ($(this).parents('.nav-menu').length) {
          $('.nav-menu .menu-active').removeClass('menu-active');
          $(this).closest('li').addClass('menu-active');
        }

        if ($('body').hasClass('mobile-nav-active')) {
          $('body').removeClass('mobile-nav-active');
          $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
          $('#mobile-body-overly').fadeOut();
        }
        return false;
      }
    }
  });

  // Header scroll class
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    } else {
      $('#header').removeClass('header-scrolled');
    }
  });

  // Skills section
  $('#skills').waypoint(function () {
    $('.progress .progress-bar').each(function () {
      $(this).css("width", $(this).attr("aria-valuenow") + '%');
    });
  }, {
    offset: '80%'
  });

  // jQuery counterUp (used in Facts section)
  $('[data-toggle="counter-up"]').counterUp({
    delay: 10,
    time: 1000
  });

  // Porfolio isotope and filter
  var portfolioIsotope = $('.portfolio-container').isotope({
    itemSelector: '.portfolio-item',
    layoutMode: 'fitRows'
  });

  $('#portfolio-flters li').on('click', function () {
    $("#portfolio-flters li").removeClass('filter-active');
    $(this).addClass('filter-active');

    portfolioIsotope.isotope({
      filter: $(this).data('filter')
    });
  });

  $(document).on('click', '.search-menu .nav-tabs li', function () {
    $('.search-menu .nav-tabs li').removeClass('active')
    $(this).addClass('active')
  });

  $('#postPropertyModal ul li').on('click', function () {
    $('.modal-backdrop.show').css({
      "display": "none"
    })
  });


  var tog=false;

  $(".set a").off("click").on("click", function (e) {
        e.preventDefault();
        //$(this).siblings("content").slideToggle(200);
        console.log(tog);

        if (tog) {
          $(".set a").removeClass("active");
          tog = false;
          $(".set a>i")
            .removeClass("fa fa-caret-up")
            .addClass("fa fa-caret-down");
        
          $(this)
            .siblings(".content")
            .slideUp(200);
            
            console.log("SlideUp")
        } 
        else {
          $(".set a").addClass("active");
          tog=true;
            $(this)
              .siblings(".content")
              .slideDown(200);
            $(".set a>i")
              .removeClass("fa fa-caret-down")
              .addClass("fa fa-caret-up");
  
              console.log("SlideDown")

        } 
  });

  $('.pp').on('mouseover', function () {
    $('.pp .arrow').css({
      "border": "solid #fff",
      "border-width": "0 3px 3px 0"
    })
  })

  $('.pp').on('mouseout', function () {
    $('.pp .arrow').css({
      "border": "solid #EC3338",
      "border-width": "0 3px 3px 0"
    })
  })

  $(window).on('scroll', function () {
    var scrollHeight = $(window).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();

    if ($(window).width() == 360) {
      if (scrollPosition >= 930) {
        $('.fa-arrow-circle-left').hide()
        $('.fa-arrow-circle-right').hide()
      } else {
        $('.fa-arrow-circle-left').show()
        $('.fa-arrow-circle-right').show()
      }
    }

    if ($(window).width() == 411) {
      if (scrollPosition >= 1011) {
        $('.fa-arrow-circle-left').hide()
        $('.fa-arrow-circle-right').hide()
      } else {
        $('.fa-arrow-circle-left').show()
        $('.fa-arrow-circle-right').show()
      }
    }

    if ($(window).width() == 414) {
      if (scrollPosition >= 1190) {
        $('.fa-arrow-circle-left').hide()
        $('.fa-arrow-circle-right').hide()
      } else {
        $('.fa-arrow-circle-left').show()
        $('.fa-arrow-circle-right').show()
      }
    }

    if ($(window).width() > 414) {
      // console.log("width " + $(window).width())
      // console.log("scroll " + scrollPosition)
    }

    if ($(window).width() > 1024) {
      if (scrollPosition >= 886) {
        $('.fa-arrow-circle-left').hide()
        $('.fa-arrow-circle-right').hide()
      } else {
        $('.fa-arrow-circle-left').show()
        $('.fa-arrow-circle-right').show()
      }
    }

    if ($(window).width() > 1366) {
      // console.log("scrollPosition " + scrollPosition)
      if (scrollPosition >= 886) {
        $('.fa-arrow-circle-left').hide()
        $('.fa-arrow-circle-right').hide()
      } else {
        $('.fa-arrow-circle-left').show()
        $('.fa-arrow-circle-right').show()
      }
    }

    $(document).on('mouseover', '.language', function () {
        // console.log("over")
        $(this).attr('size', $('option').length);
      },
      function () {
        $(this).attr('size', 1);
      });


  });
});
